import { ChangeDetectorRef, Component, DoCheck, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstantsService } from '../app-constants.service';
import { AppState } from '../app.service'
import { UtilityService } from '../common-services/utility.service';
import { DailyStepsService } from '../daily-steps/daily-steps.service';
import { Habit } from '../habit/habit.model';
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { Path } from '../path/path.model';
import { SpinnerService } from '../spinner.service';
import { ToasterService } from '../toaster.service';
import { User } from '../user/user.model';
import { fadeInRightAnimation, fadeOutLeftAnimation, fadeOutRightAnimation, fadeOutUpAnimation, slideInLeftAnimation, slideInRightAnimation, slideInUpAnimation, slideOutDownAnimation, zoomInAnimation, slideInDownAnimation } from 'angular-animations';
import { SessionService } from '../session/session.service';
import { log } from 'console';
import { Platform } from '@ionic/angular';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { HomeUiService } from '../new-design/home-ui.service';
import { I18n } from '../i18n.service';
import * as _ from 'underscore';
import { YourPathComponent } from '../new-design/your-path/your-path.component';

@Component({
  selector: 'mobile-weldonescreen',
  templateUrl: './mobile-weldonescreen.component.html',
  styleUrls: ['./mobile-weldonescreen.component.scss'],
  animations: [
    fadeOutRightAnimation(),
    fadeOutLeftAnimation(),
    slideInUpAnimation(),
    fadeOutUpAnimation(),
    zoomInAnimation(),
    slideInRightAnimation({ duration: 1600 }),
    slideInLeftAnimation({duration:500}),
    slideInDownAnimation({ duration: 1000 }),
    fadeInRightAnimation({ duration:300 }),
  ]
})
export class MobileWeldonescreenComponent implements OnInit, DoCheck, OnDestroy {
  currentUser: User;
  currentPath: Path;
  currentDay: number;
  habit: Habit;
  stepScreensIds;
  postText:any = ''
  formRating1:any = 0
  scheduleDay: any;
  scheduledStep: any;
  dayHasContent: boolean;
  maxAllowedScheduleDay: any;
  activeDay: any;
  nextDay: any;
  titleOfTask: string;
  inoutScreen: any;
  isMobile: boolean;
  isMobileWebIos = false;
  isMobileWeb=false;
  allowScreenCount: boolean
  previousDayCompleted;
  public pathRegistration: any;
  currentScreenIndex = 0;
  currentStepActionPoints;
  @ViewChild('gifImage', { static: false }) gifImage: ElementRef;
    @ViewChild("textarea") txtarea: ElementRef;
  options: [
    {
      label: 'Fading Exits',
      animations: [
        'fadeOutLeft',
        'fadeOutRight',
        'fadeOutUp',
      ]
    },
    {
      label: 'Sliding Entrances',
      animations: ['slideInUp',]
    },
    {
      label: 'Zoom Entrances',
      animations: ['zoomIn', 'zoomInDown', 'zoomInLeft', 'zoomInRight', 'zoomInUp']
    },
  ]
  animationState = false;
  showDoors = true
  showBottomTextarea = false
  showInstruction = false
  @ViewChild('textAreaWrapper', { static: false }) textAreaWrapper: ElementRef;
  animationBoard = false;
  animateLabel = false
  showLabel = false
  animateStars = false
  showRatingBox = false
  starRating: string
  hideHorizontalLine = true
  @ViewChild('textAreaContainer', { static: false }) textAreaContainer: ElementRef;  
  dailyStepsArray;
  isIconDisliked: boolean = false;
  isIconLiked: boolean = false;
  mobileWeldoneScreenIds = [];
  isBadgesExpanded;
  unlockStepInoutScreenData;
  durationOfTask;
  unAttemptedActionPoints = {};
  totalUnAttemptedPoints = 0;
  state = false;
  reviewState = false;
  currentNumber =0
  @ViewChild("stepTitle") stepTitle: ElementRef;
  showStepTitle=true;
  constructor( 
    private appState: AppState, 
    public route: ActivatedRoute,
    private router: Router,
    private spinnerService: SpinnerService,
    private homev5Service: HomeV5Service,
    private cdRef: ChangeDetectorRef,
    private toasterService: ToasterService,
    private dailyStepsService: DailyStepsService,
    private appConstantService: AppConstantsService,
    private utilService: UtilityService,
    private elementRef: ElementRef, 
    private render: Renderer2,
    private i18n: I18n,
    private sessionService: SessionService,
    private keyboard: Keyboard,
    private platform: Platform,
    public homeUIService: HomeUiService,) {
     
    this.currentUser = this.appState.get('currentUser');
    this.currentPath = this.appState.get('currentPath');
    this.isMobile = this.appState.get('isMobile')
    this.currentDay = this.appState.get('currentScheduleDay')
    this.habit = this.findForCurrentDay(this.currentPath.habits)
    this.maxAllowedScheduleDay = this.appState.get('daysOfContent')
    this.scheduleDay = +this.appState.get('currentScheduleDay')
    this.pathRegistration = this.appState.get('pathRegistration')
    this.inoutScreen = this.findForCurrentDay(this.currentPath.inoutScreens)
    console.log("inoutScreenData", this.inoutScreen);
    // this.unlockStepInoutScreenData; = this.findFornextDay(this.currentPath.inoutScreens)
    if (this.appState.get('routine_schedule_day')) {
      this.currentDay = this.appState.get('routine_schedule_day')
      this.appState.set('routine_schedule_day', null)
    }
    for(let i=this.currentDay + 1; i<= this.maxAllowedScheduleDay; i++) {
      this.dayHasContent = this.currentPath.checkContentForDay(i);
      if(this.dayHasContent) {
        this.nextDay = i;
        this.scheduleDay =i;
        break;
      }
    }
    this.stepScreensIds = this.appState.get("stepScreenIds");
    this.currentStepActionInfo();
    this.scheduledStep = this.appState.get('scheduleDayForCurrentStep')  
    
    this.setDoorClosedAnimation()
   }

  ngDoCheck() {
    this.allowScreenCount = this.appState.get('allowScreenCount')
  }

  currentStepActionInfo() {
    let data = {
      'user_id': this.currentUser.id,
      'path_id': this.currentPath.id,
      'currentDay': this.currentDay
    }
    this.homeUIService.getStepwiseActionPoint(data).subscribe((res) => {
      this.currentStepActionPoints = res.data;
      //  document.documentElement.style.setProperty('--num', this.currentStepActionPoints?.total_action_points.toString());
      // let id = document.getElementsByClassName('number')
      // if (id) {
      //   id[0].setAttribute(
      //     "style",
      //     `content: counter(${this.currentStepActionPoints?.total_action_points});counter-reset: ${this.currentStepActionPoints?.total_action_points}`
      //   );
      // }
      if (this.currentStepActionPoints?.total_action_points > 0) {
        this.startCounterAnimation(this.currentStepActionPoints?.total_action_points)
      }      
      let poll, documents, notification, profile, accountability, managerEmail, habit, action, survey, reflection,question;
      habit = this.stepScreensIds?.find((item) => item?.type === "routine");
      poll = this.stepScreensIds?.find((item) => item?.type === "poll");
      documents = this.stepScreensIds?.find((item) => item?.type === "document");
      notification = this.stepScreensIds?.find((item) => item?.type === "notification");
      profile = this.stepScreensIds?.find((item) => item?.type === "profile");
      accountability = this.stepScreensIds?.find((item) => item?.type === "accountability");
      managerEmail = this.stepScreensIds?.find((item) => item?.type === "managerEmail");
      action = this.stepScreensIds?.find((item) => item?.type === "action");
      reflection = this.stepScreensIds?.find((item) => item?.type === "reflection");
      survey = this.stepScreensIds?.find((item) => item?.type === "survey");
      question = this.stepScreensIds?.find((item) => item?.type === "question");
      if (this.currentStepActionPoints.document == 0 && documents) {
        this.totalUnAttemptedPoints += documents.actionPoints;
        this.unAttemptedActionPoints['document'] = documents.actionPoints;
      }
      if (habit) {
        this.totalUnAttemptedPoints += habit.actionPoints;      
        this.unAttemptedActionPoints['habit'] = habit.actionPoints;
      }
      if (poll) {
        if (this.currentStepActionPoints.poll == 0) {
           this.totalUnAttemptedPoints += poll.actionPoints;
           this.unAttemptedActionPoints['poll'] = poll.actionPoints;
        }
        else if (this.currentStepActionPoints.poll > 0) {
          // let pointsDiff = poll.actionPoints - this.currentStepActionPoints.poll;
          // if (pointsDiff!=0) {
            this.totalUnAttemptedPoints += poll.actionPoints;
             this.unAttemptedActionPoints['poll'] = poll.actionPoints;
          // // }
          //   // else {
          //    this.totalUnAttemptedPoints += pointsDiff;
             //this.unAttemptedActionPoints['poll'] = pointsDiff;
          //}
         
        }
       
      }
      if (this.currentStepActionPoints.notification == 0 && notification) {
        this.totalUnAttemptedPoints += notification.actionPoints; 
        this.unAttemptedActionPoints['notifications'] = notification.actionPoints;
      }
      if (this.currentStepActionPoints.profile == 0 && profile) {
        this.totalUnAttemptedPoints += profile.actionPoints; 
        this.unAttemptedActionPoints["profile"] = profile.actionPoints;
      }
      if (this.currentStepActionPoints.accountability == 0 && accountability) {
        this.totalUnAttemptedPoints += accountability.actionPoints; 
        this.unAttemptedActionPoints['accountability'] = accountability.actionPoints;
      }
      if (this.currentStepActionPoints.manager == 0 && managerEmail) {
        this.totalUnAttemptedPoints += managerEmail.actionPoints; 
        this.unAttemptedActionPoints['managerEmail'] = managerEmail.actionPoints;
      }
      if (this.currentStepActionPoints.action == 0 && action) {
        this.totalUnAttemptedPoints += action.actionPoints; 
        this.unAttemptedActionPoints['action'] = action.actionPoints;
      }
      if (this.currentStepActionPoints.content_survey == 0 && survey) {
      this.totalUnAttemptedPoints += survey.actionPoints; 
      this.unAttemptedActionPoints['content_survey'] = survey.actionPoints;
    }
      if (this.currentStepActionPoints.reflection == 0 && reflection) {
       this.totalUnAttemptedPoints += reflection.actionPoints; 
       this.unAttemptedActionPoints['reflection'] = reflection.actionPoints;
     }
      if (this.currentStepActionPoints.question == 0 && question) {
       this.totalUnAttemptedPoints += question.actionPoints; 
       this.unAttemptedActionPoints['question'] = question.actionPoints;
     }      
      
    })
  }
  startCounterAnimation(targetNumber) {
    const element = document.querySelector('.number') as HTMLElement;
    if (element) {
      // element.style.setProperty('--num', targetNumber.toString());
      setTimeout(() => {
         this.animateCounter(0, targetNumber, 1000);
      }, 2150);     
    }
  }

  animateCounter(start: number, end: number, duration: number) {
    const stepTime = Math.abs(Math.floor(duration / (end - start)));
    let current = start;
    const step = () => {
      current += 1;
      this.currentNumber = current;
      if (current < end) {
        setTimeout(step, stepTime);
      }
    };
    step();
    console.log("this.currentNumber",this.currentNumber);
  }
  ngOnInit() {
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    this.dailyStepsArray = this.appState.get('DailyStepsData')
    console.log("dailyStepsArray", this.dailyStepsArray,this.scheduledStep , this.currentDay, this.dailyStepsArray[this.currentDay]?.stepStatus);
    let isStepRestartedFromWeldone = this.appState.get("isCurrentStepRestarted")
    let isInitialWeldoneScreen =  this.appState.get('showInitialWeldone')
    // if ((this.scheduledStep == this.currentDay &&this.dailyStepsArray[this.currentDay]?.stepStatus==undefined )|| (this.scheduledStep == this.currentDay+1 && this.dailyStepsArray[this.currentDay]?.stepStatus==undefined )|| isStepRestartedFromWeldone ) {
    if(isStepRestartedFromWeldone ||isInitialWeldoneScreen) {
      this.mobileWeldoneScreenIds = [
        "step-action-points-screen",
      //  "step-badges-screen",
        "step-final-weldone-screen",
        //  "step-review-weldone-screen",
      ];
      this.appState.set("showInitialWeldone", false);
      this.appState.set("isCurrentStepRestarted", false);
    console.log("this.mobileWeldoneScreenIds", this.mobileWeldoneScreenIds, this.mobileWeldoneScreenIds['action-points-screen']);
   }
    else {
      this.reviewState=true
      this.mobileWeldoneScreenIds = [
        'step-review-weldone-screen'
      ]
    }
    this.dailyStepsService.init()
    this.appState.set('allowNext', false)
    this.getActions(this.currentUser.id, this.currentPath.id)
  }
  private capitalizeSentences(text: string): string {
    return text.replace(/(?:^|\.\s)([a-z])/g, (match) => match.toUpperCase());
  }
  capitalLine(event): void {
    console.log("test cap")
    let ele = document.getElementsByClassName("weldone-textMessage");
    if (event && event.target.value) {
      this.postText = this.capitalizeSentences(event.target.value);
    }
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  ngAfterViewInit(){
    setTimeout(() => {
      //STOP confetti animation
      this.gifImage?.nativeElement?.classList?.remove('bg_celebration')

      //Enable rating area with animation
      this.showRatingBox = true
      this.animateStars = false
      setTimeout(() => {
        this.animateStars = true
      }, 10);

      //Enable text area with animation

    }, 200);

  }
 
  goToHome(){
    localStorage.setItem('stepCompleted',this.scheduledStep.toString())
    this.appState.set('isNewStep', undefined)
    this.appState.set('isHomeScreen',true)
    this.router.navigate(['/main'])
    this.appConstantService.removeAppStateData()
    if (this.allowScreenCount && this.currentDay ==this.scheduledStep && this.dailyStepsArray[this.scheduledStep-1]?.stepStatus!='Review'){
      this.utilService.setScreenCount()
    }   
  }

  findForCurrentDay(collection: any): any {
    return collection.find(
      item => item.startDay <= this.currentDay + 1 && this.currentDay + 1 <= item.endDay
    )
  }

  giveFeedback() {
    var step_rating = localStorage.getItem("step_score")
    var step_comment = localStorage.getItem("step_comment")
    try {
      const requestData = {
        'user_id': this.currentUser.id,
        'path_id': this.currentPath.id,
        'step_score': step_rating,
        'step_comment': step_comment,
      }
      if (requestData.step_comment === "" && requestData.step_score === "0") {
        this.toasterService.warning(this.i18n.t('Please enter a comment'))
        return
      } else {
        this.homev5Service.postStepFeedback(requestData).subscribe(
          (response) => {
            // this.toasterService.success(this.i18n.t('Thank you for your feedback!'))
            this.postText = ''
            this.animateStaticInfo()
            this.cdRef.detectChanges()
          },
          (error) => {
            this.toasterService.error(this.i18n.t('Server or connection issue. Please check your internet connection.'))
          }
        )       
      }

    } catch (o_O) {
      console.log(o_O);
    }

  }

  isDayLocked(nextDay){
    localStorage.setItem('stepCompleted',this.scheduledStep.toString())
    // if (this.allowScreenCount && this.currentDay ==this.scheduledStep && this.dailyStepsArray[this.scheduledStep-1]!='Review'){
    //   this.utilService.setScreenCount()
    // }
    this.appState.set('isHomeScreen',true)
    var currentDay = nextDay - 1
    this.appState.set('selectedLevelIds', undefined)
    let levelSaveData = this.appState.get('levelSaveData')
    if (levelSaveData) {
      localStorage.setItem(
        `Toggle:Day:${levelSaveData.currentDay}:LevelNumber:${levelSaveData.levelId}:true`,
        'true'
      )
      this.appState.set('showUpdatedScreens', undefined)
      this.appState.set('levelSaveData', undefined)
    }

    if (nextDay >= this.scheduledStep && this.scheduledStep <= this.maxAllowedScheduleDay && (this.dailyStepsArray[nextDay-1]?.stepStatus==undefined || this.dailyStepsArray[nextDay-1]?.stepStatus==null)) {
      localStorage.setItem("isBeginClicked", "true");
      this.unlockReplay(currentDay)
      
    } else {

      this.replay(nextDay)
    }
  }


  replay(i) {
    let updateScheduleDay=i;
    this.appState.set('maxAllowedScheduleDay', updateScheduleDay);
    this.appState.set('currentScheduleDay', updateScheduleDay);
    this.appState.set('scheduleDayForCurrentStep', updateScheduleDay);
    this.scheduledStep = this.appState.get('scheduleDayForCurrentStep');
      localStorage.removeItem(
        `Toggle:User:${this.currentUser.id}:DayProgress:${this.currentPath.id}:${!(i == undefined) ? i : this.scheduleDay
        }`
      );
      this.router.navigate([
        '/content',
        !(i == undefined) ? i : this.scheduledStep,
      ])
    // this.router.navigate([
    //   '/content',
    //   !(i == undefined) ? i : this.scheduleDay,
    // ])
    return false
  }

  unlockReplay(i) {
    if (i <= 0) {
      return
    }

    let updateScheduleDay = i + 1
    let data = {
      'schedule_day': updateScheduleDay,
      'user_id': this.currentUser.id
    }

    // for(let s=i; s>=0; s--) {
    //   this.dayHasContent = this.currentPath.checkContentForDay(s);
    //   if (this.dayHasContent) {
    //     this.previousDayCompleted = !!this.homev5Service.checkPrevDayCompleted(
    //       this.pathRegistration,
    //       s,
    //       this.currentUser,
    //       this.currentPath
    //     )
    //     break;
    //   }
    // }
    
    // let stepsArray = this.homev5Service.removeDuplicates(this.pathRegistration.screensViewed, "step");
    // stepsArray.forEach(element => {
    //   if(element.step === this.scheduleDay && element.totalScreens === element.visitedScreens){
    //     this.previousDayCompleted = true
    //   }
    // });
  
    // if (this.previousDayCompleted) {

      this.homev5Service.updateScheduleDay(data).subscribe(
        (response) => {
          if (response.success == true) {
            this.appState.set('allowScreenCount', true)
            this.appState.set('isNewStepUnlocked', true)
            this.appState.set('maxAllowedScheduleDay', updateScheduleDay)
            this.appState.set('currentScheduleDay', updateScheduleDay)
            this.appState.set('scheduleDayForCurrentStep', updateScheduleDay)
            this.appState.set('indexOfLevel', 0)
            this.appState.set('levelUpdated', undefined)
            // this.maxAllowedScheduleDay = this.appState.get(
            //   'maxAllowedScheduleDay'
            // ) // LUMBA-1379
            this.scheduleDay = this.appState.get('currentScheduleDay') // LUMBA-1379
            this.scheduledStep = this.appState.get('scheduleDayForCurrentStep')

            this.appState.set('LevelNumber',1)

            localStorage.removeItem(
              `Toggle:User:${this.currentUser.id}:DayProgress:${this.currentPath.id}:${
                !(i == undefined) ? i + 1 : this.scheduledStep
              }`
            )
            // this.spinnerService.on()
            this.router.navigate([
              '/content',
              !(i == undefined) ? i + 1 : this.scheduledStep,
            ])
            return false

          } else {
            this.toasterService.warning(this.i18n.t('Server or connection issue. Please check your internet connection.'))
          }

        },
        (error) => {
          this.toasterService.warning(this.i18n.t('Server or connection issue. Please check your internet connection.'))
          console.log('Response of update  error', error)
        }
      )
    // } else {
    //   this.toasterService.warning(this.i18n.t('Please complete previous step'))
    // }
  }


  getTodaysTask(){
    if(this.inoutScreen){
      this.titleOfTask = this.inoutScreen.homepageSubtext.text;
      return this.titleOfTask;
      }
  }

  changeStyle(){
    document.getElementById('stepDiv').classList.add('unlocked_screen');
  }

  getActions(userId: number, pathId: number) {
    const requestData = {
      'user_id': userId,
      'path_id': pathId
    }
    this.dailyStepsService.getActionsList(requestData).subscribe((response) => {
      this.appState.set('todoRoutines',this.homev5Service.removeDuplicates(response.data[0].routine, "routine_info_id"))
      this.appState.set('todoActions',response.data[0].action)
    }, (error) => {
      console.log('error :', error);
    })
  }

  setDoorClosedAnimation(){
    //Doors opening animation
    this.animationState = false;
    setTimeout(() => {
      this.animationState = true;
    }, 0);
    setTimeout(() => {
      this.showDoors = false;
    }, 1000);
    setTimeout(() => {
      this. showStepTitle = false;
    }, 1400);
  }

  onTextAreaClick(){
    this.showInstruction = true
    this.hideHorizontalLine = false
    // setTimeout(() => {
    //   this.textAreaWrapper.nativeElement.classList.add('textArea-wrapper-onClick')
    // }, 10);
    this.enableHeight(this.textAreaContainer, this.isMobile, this.platform, this.keyboard)
  }

  post(){
    this.showInstruction = false
    this.hideHorizontalLine = true
    // setTimeout(() => {
    //   this.textAreaWrapper?.nativeElement?.classList?.remove('textArea-wrapper-onClick')
    // }, 10);
    localStorage.setItem('step_comment',this.postText);
    this.giveFeedback()
  }

  setStars(formRating1){
    this.starRating = this.sessionService.returnStarsAsString(formRating1)
    localStorage.setItem('step_score',JSON.stringify(this.formRating1));
  }

  animateStaticInfo(){
    setTimeout(() => {
      this.showLabel = true
    }, 50);
    this.animateLabel = true;
    setTimeout(() => {
      this.animateLabel = false;
    }, 1000);
    setTimeout(() => {
      this.showLabel = false
    }, 1000);
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.textAreaWrapper?.nativeElement.contains(event.target)) {
      console.log('clicked inside');
    } else {
      this.showInstruction = false
      this.hideHorizontalLine = true
      // this.onBackgroundTap();
      // setTimeout(() => {
      //   this.textAreaWrapper?.nativeElement.classList.remove('textArea-wrapper-onClick')
      // }, 10);
    }
  }

  enableHeight(textareaContainer, isMobile, platform, keyboard){
    //this code is for increasing the height of the text area for android device when keyboard pops up
    if(isMobile){
      if (platform.is("android") || platform.is("ios")) {
        //  this.textareaContainer.nativeElement?.setAttribute('style', 'margin-bottom:200px;')
        let id = document.getElementsByClassName("wrap-next-btn");
        let id2= document.getElementsByClassName('weldone-action-scroll')
        let id3= document.getElementsByClassName('bottom-textarea')
        keyboard.onKeyboardDidShow().subscribe((info) => {
          console.log("keyboard will show with height", info.keyboardHeight);
          // textareaContainer.nativeElement.style.setProperty(
          //   'bottom',
          //   `0px`
          // );
          if(platform.is("android")&& id3 ){
            id3[0].setAttribute('style','bottom: calc(100vh - 466px)!important') 
          }
          if (id) {
            id[0].setAttribute('style','position:relative;width: calc(100% - 40px)')
          }
          if (id2) {
            id2[0].setAttribute('style','overflow-y:hidden;height:calc(100vh - 74px); padding-bottom: 185px;')
          }
          // textareaContainer.nativeElement.style.setProperty(
          //   "position",
          //   "relative"
          // );
        });

        keyboard.onKeyboardDidHide().subscribe((info) => {
          console.log("keyboard did hide", info.keyboardHeight);
          console.log("keyboard did hide", info);
          // textareaContainer.nativeElement.style.setProperty(
          //   'margin-bottom',
          //   `${0}px`
          // );
          // textareaContainer.nativeElement.style.setProperty(
          //   'bottom',
          //   `${75}px`
          // );
          // textareaContainer.nativeElement.style.setProperty(
          //   "position",
          //   "fixed"
          // );
          // if (id) {
          //   id[0].setAttribute("style", "position:fixed; bottom: 0.5%;width: calc(100% - 70px)");
          // }
          // if (id2) {
          //   id2[0].setAttribute('style','overflow-y:auto;height:calc(100vh - 74px); padding-bottom: 185px;')
          // }
          if(platform.is("android")&& id3 ){
            id3[0].setAttribute('style','bottom:0!important') 
          }
        });
      }
      // else if (platform.is('android')) {

      // }
    }
  }

   dislike(val) {
    if (!val) {
        setTimeout(() => {
          this.isIconDisliked = true;
          this.isIconLiked = null;
        }, 100);
    }
  }

  like(val) {
    if (val) {
       setTimeout(() => {
         this.isIconLiked = true;
         this.isIconDisliked = null;
       }, 100);
    }
  }

  nextStepEndScreen() {
    this.currentScreenIndex++;
    this.rightSwipeAnimation();
  }
  rightSwipeAnimation() {
    this.state = false;
    setTimeout(() => {
      this.state = true;
    }, 1);
  }
  
  onBackgroundTap() {
    if (this.showBottomTextarea && !this.showInstruction) {
      // this.keyboard.hide();
      let id = document.getElementsByClassName("wrap-next-btn");
      let id2 = document.getElementsByClassName("weldone-action-scroll");
      setTimeout(() => {
        if (id) {
          id[0].setAttribute("style", "position:fixed; bottom: 1%;width: calc(100% - 70px)");
        }
        if (id2) {
          id2[0].setAttribute('style', 'overflow-y:auto;height:calc(100vh - 74px); padding-bottom: 185px;')
        }
      },90)
      setTimeout(() => {
        this.showBottomTextarea = false;
      }, 100);
      console.log("called on background", this.showBottomTextarea)
    }
  }
  showTextArea() {
   setTimeout(() => {
     this.showBottomTextarea = true;
   }, 190);   
    console.log("show textarea called", this.showBottomTextarea)
  this.animationBoard = false;
  setTimeout(() => {
    this.animationBoard = true;
    this.txtarea?.nativeElement?.focus();
    this.txtarea.nativeElement.autofocus=true
  }, 200);  

      this.enableHeight(this.textAreaContainer, this.isMobile, this.platform, this.keyboard)  
  }

  expandBadges(val) {
    this.isBadgesExpanded = val;
  }
  changeFont() {
  
    const element = this.stepTitle?.nativeElement;
    const specificElement = element?.querySelector('p') || element?.querySelector('h1') || element?.querySelector('h2');
    if(specificElement) {
      this.render.setStyle(specificElement, 'font-size', '20px');
      this.render.setStyle(specificElement, 'font-weight', '400');
      this.render.setStyle(specificElement, 'margin-bottom', '0px');
      this.render.setStyle(specificElement, 'font-style', 'normal');
      this.render.setStyle(specificElement, 'line-height', 'normal');
      this.render.setStyle(specificElement, 'font-family', 'Roboto');
     this.render.setStyle(specificElement, 'word-break', 'break-word');
     this.render.setStyle(specificElement, 'overflow', 'hidden');
     this.render.setStyle(specificElement, ' text-overflow', 'ellipsis');
     this.render.setStyle(specificElement, 'display', '-webkit-box');
     this.render.setStyle(specificElement, '-webkit-line-clamp', '2');
     this.render.setStyle(specificElement, '-webkit-box-orient', 'vertical');

    }
  return true;
  }
    getTaskDurations() {
    if (this.inoutScreen && !_.isUndefined(this.inoutScreen)) {
      if (this.inoutScreen.stime && this.inoutScreen.etime) {
        this.durationOfTask =
          this.inoutScreen.stime + " - " + this.inoutScreen.etime;
        return this.durationOfTask;
      } else if (!this.inoutScreen.stime && this.inoutScreen.etime) {
        this.durationOfTask = this.inoutScreen.etime;
        return this.durationOfTask;
      } else {
        return "5 - 10";
      }
    } else {
      return "5 - 10";
    }
    }
  openYourPaths() {
    this.router.navigate(["/main"]);
    setTimeout(() => {
      this.homeUIService.openNextComponent(YourPathComponent);
    }, 300);
  }
  restartStep(val,day) {
    this.appState.set("isCurrentStepRestarted",val)
    this.router.navigate(["/content", day]);
  }
  moveToLastScreen() {
    let screenIds=  this.appState.get('currentStepScreenIds')
    this.router.navigate(['/content', this.currentDay], { queryParams: { startFromScreen: screenIds[screenIds?.length-1] } })
  }
  @HostListener('touchend', ['$event'])
    onTouchEnd(event: TouchEvent) {
      this.addInputEventListeners();
  }
  addInputEventListeners() {
    const inputs = document.querySelector("textarea");
     let id = document.getElementsByClassName("wrap-next-btn");
    let id2 = document.getElementsByClassName("weldone-action-scroll");
    if (inputs) {
          this.render.listen(inputs, "focus", () => {
      if (id) {
            id[0].setAttribute('style','position:relative;width: calc(100% - 40px)')
          }
          if (id2) {
            id2[0].setAttribute('style','overflow-y:hidden;height:calc(100vh - 74px); padding-bottom: 185px;')
          }
    });
    }

  }
   removeInputEventListeners() {
    const inputs = document.querySelector("textarea");
    if (inputs) {
       this.render.listen(inputs, "focus", null);
    }
   
  }
  ngOnDestroy(): void {
    this.appState.set("stepScreenIds", null);
    // this.appState.set("isCurrentStepRestarted", false);
    this.appState.set('currentStepScreenIds', null)
    this.removeInputEventListeners();
  }
}
